<template>
  <div>
    <h5 class="is-size-5 has-text-centered">
      {{ doc.name }}
    </h5>

    <div class="columns">
      <div class="column">
        <a-card :editable="false">
          <a-input
            field="name"
            type="text"
          />

          <a-input
            field="officialName"
          />

          <a-input
            field="address"
          />

          <a-input
            field="city"
          />

          <a-input
            field="zip"
          />

          <a-input
            field="country"
          />

          <a-input
            field="regNr"
          />

          <a-input
            field="vatNr"
          />
        </a-card>
      </div>

      <div
        v-if="keys(doc.contacts).length"
        class="column"
      >
        <a-card :editable="false">
          <contacts />
        </a-card>
      </div>
    </div>

    <add-button
      v-if="canUpdate"
    />
  </div>
</template>

<script>
import { keys } from 'lodash'
import { mapGetters } from 'vuex'
import formMixin from './formMixin'
import Contacts from '@/components/Contacts/Contacts'
import AddButton from './Supplier/AddButton'

export default {
  components: {
    Contacts,
    AddButton
  },
  mixins: [formMixin],
  computed: {
    ...mapGetters({
      canUpdate: 'currentDoc/canUpdate'
    })
  },
  methods: {
    keys
  }
}
</script>
